import React, { useState } from 'react';
import Chart from 'react-apexcharts';

function JobMonitoringReport() {
    const dropdownOptions = ["Daily", "Monthly", "Yearly"];
    const [data, setData] = useState({
        series: [
            {
                name: 'Success',
                data: [31, 40, 28, 51, 42, 82, 56],
            },
            {
                name: 'Failure',
                data: [11, 32, 45, 32, 34, 52, 41],
            }
        ],
        options: {
            chart: {
                height: 250,
                type: 'area',
                toolbar: {
                    show: false,
                },
            },
            title: {
                text: 'Job Monitoring',
                align: 'left',
                offsetX: 10,
                offsetY: -3,
                floating: true,
                style: {
                    fontSize: '16px',
                    fontWeight: 'normal',
                    color: '#333',
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                floating: true,
                offsetX: 100,
                offsetY: 2,
                itemMargin: {
                    horizontal: 3,
                    vertical: 0,
                },
                customLegendItems: false,
            },
            markers: {
                size: 4,
            },
            colors: ['#2eca6a', '#ff771d', '#ff771d'],
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.3,
                    opacityTo: 0.4,
                    stops: [0, 90, 100],
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            xaxis: {
                type: 'datetime',
                categories: [
                    '2018-09-19T00:00:00.000Z',
                    '2018-09-19T01:30:00.000Z',
                    '2018-09-19T02:30:00.000Z',
                    '2018-09-19T03:30:00.000Z',
                    '2018-09-19T04:30:00.000Z',
                    '2018-09-19T05:30:00.000Z',
                    '2018-09-19T06:30:00.000Z',
                ],
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm',
                },
            },
        },
    });


    return (
        <div className="card">
            <div className="card-body" style={{ position: 'relative', padding: '20px' }}>
                {/* Select box */}
                <select
                    className="testing-custom-chart-dropdown"
                    style={{
                        position: 'absolute',
                        top: '14px',
                        right: '10px',
                        width: '100px',
                        zIndex: 10, // Ensures it's above the chart
                    }}
                >
                    {dropdownOptions.map((option) => (
                        <option key={option} value={option.toLowerCase()}>
                            {option}
                        </option>
                    ))}
                </select>
                <Chart
                    options={data.options}
                    series={data.series}
                    type={data.options.chart.type}
                    height={data.options.chart.height}
                />
            </div>
        </div>

    );
}

export default JobMonitoringReport;
