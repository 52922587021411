import React, { useRef, useState, useEffect } from 'react';
import * as echarts from 'echarts';
import Select from 'react-select';
import { Modal, Button } from 'react-bootstrap';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';
import { getChartTimeDropDown, } from '../../ProHelpers/CommonUtils'

function MessageProcessingChart() {
    const systemTimeZone = sessionStorage.getItem('SystemTimeZone');
    const dropdownOptions = [
        { label: "Daily", value: "last24Hours" },
        { label: "Monthly", value: "last30Days" },
    ];
    const [timeRange, setTimeRange] = useState("last30Days"); // Default time range
    const [showViewModal, setShowViewModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchPackageQuery, setSearchPackageQuery] = useState('');
    const [searchArtifactsQuery, setSearchArtifactsQuery] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedPackageItems, setSelectedPackageItems] = useState([]);
    const [selectedArtifactsItems, setSelectedArtifactsItems] = useState([]);
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const [chartVisible, setChartVisible] = useState(false);
    const [chartloading, setChartLoading] = useState(false);
    const [filterloading, setFiltertLoading] = useState(false);
    const [packageloading, setPackageLoading] = useState(false);
    const [artifactsloading, setArtifactsLoading] = useState(false);

    const [responseData, setResponseData] = useState([]);
    const [filterSystemsData, setFilterSystemsData] = useState([]);
    const [filterPackageData, setFilterPackageData] = useState([]);
    const [filterArtifactsData, setFilterArtifactsData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedValue, setSelectedValue] = useState("0");

    const handleSearchChange = (e) => setSearchQuery(e.target.value);
    const handlePackageSearchChange = (e) => setSearchPackageQuery(e.target.value);
    const handleArtifactsSearchChange = (e) => setSearchArtifactsQuery(e.target.value);
    const handleViewClick = () => {
        setSelectedItems([]);
        setShowViewModal(true); // Show the View modal
    };
    const handleCloseViewModal = () => {
        setShowViewModal(false);
    };
    const toggleChartVisibility = () => {
        setChartVisible(prevState => !prevState); // Toggle visibility
    };

    const handleItemSelect = (item) => {
        if (!selectedItems.includes(item)) {
            setSelectedItems((prev) => [...prev, item]);
            setSearchQuery('');
            setFormData((prev) => ({
                ...prev,
                Systems: {
                    ...prev.Systems,
                    filter_options: [...prev.Systems.filter_options, item],
                },
            }));
        }
    };

    const getTimeZone = (timeZone) => {
        if (!timeZone) {
            // Fallback to browser timezone if systemTimeZone is null or empty
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return timeZone;
    };

    const handleItemRemove = (item) => {
        setSelectedItems((prev) => prev.filter((i) => i !== item));
        setFormData((prev) => ({
            ...prev,
            Systems: {
                ...prev.Systems,
                filter_options: prev.Systems.filter_options.filter((i) => i !== item),
            },
        }));
    };

    // Handler for checkbox change
    const handleCheckboxChange = (name, isChecked) => {
        // setFormData((prevData) => ({
        //     ...prevData,
        //     [name]: {
        //         ...prevData[name],
        //         checked: isChecked,
        //     },
        // }));
        setFormData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: {
                    ...prevData[name],
                    checked: isChecked,
                },
            };

            // Add logic only for the "Systems" checkbox
            if (name === "Systems") {
                // Reset selected value when unchecking
                if (!isChecked) {
                    setSelectedValue("0"); // Reset dropdown value
                }
            }
            if (name === "Package") {
                // Reset selected value when unchecking
                if (isChecked) {
                    console.log("Package Checked");
                    setPackageLoading(true);
                    fetchFilterDataAPI("PackageName");
                } else {
                    console.log("Package unchecked");
                }
            }
            if (name === "Artifacts") {
                // Reset selected value when unchecking
                if (isChecked) {
                    console.log("Artifacts Checked");
                    setArtifactsLoading(true);
                    fetchFilterDataAPI("PackageName");
                } else {
                    console.log("Artifacts unchecked");
                }
            }
            return updatedData;
        });
    };

    // Handler for select box change
    const handleTypeChange = (name, selectedType) => {
        // setFormData((prevData) => ({
        //     ...prevData,
        //     [name]: {
        //         ...prevData[name],
        //         type: selectedType,
        //     },
        // }));
        setFormData((prevData) => ({
            ...prevData,
            [name]: {
                ...prevData[name],
                type: selectedType,
            },
        }));

        // Check if the "Systems" checkbox is selected and the selected option is not the default "Select"
        if (formData.Systems?.checked && selectedType !== "0") {
            // Call the API with the selected value (and any other required parameters)
            //
            setFiltertLoading(true);
            fetchSystemsFilterDataAPI(selectedType);
            console.log("Modal fetch API: ", selectedType);
        }
    };

    const fetchFilterDataAPI = async (selectedCheckedOption) => {
        console.log("Check Option API call: ", selectedCheckedOption);
        const requestBody = {
            'ActionType': 'message_processing_filter',
            'CustomerID': sessionStorage.getItem('CustomerID'),
            'selectedOption': selectedCheckedOption,
        };

        try {
            console.log("Request Data: ", requestBody);
            const response = await AxiosInstance.post('/chart-data', requestBody);
            console.log("Response Data: ", response);
            console.log("Response Filter Data: ", response.data.message);
            if (response && response.data && response.data.message) {
                if (response.data.message.length > 0) {
                    if (response.data.status == "success") {
                        if (formData.Package?.checked) {
                            console.log("Added to Package Data: ", response.data.message);
                            setFilterPackageData(response.data.message);
                        }
                        if (formData.Artifacts?.checked) {
                            console.log("Added to Artifacts Data: ", response.data.message);
                            setFilterArtifactsData(response.data.message);
                        }
                    }
                } else {
                    setFilterSystemsData([]);
                }
            } else {
                setFilterSystemsData([]);
            }
        } catch (err) {
            setPackageLoading(false);
            setArtifactsLoading(false);
        } finally {
            setPackageLoading(false);
            setArtifactsLoading(false);
        }
    }

    const fetchSystemsFilterDataAPI = async (selectedType) => {
        console.log("Modal fetch API: ", selectedType);
        const requestBody = {
            'ActionType': 'message_processing_filter',
            'CustomerID': sessionStorage.getItem('CustomerID'),
            'selection': selectedType,
        };

        try {
            console.log("Request Data: ", requestBody);
            const response = await AxiosInstance.post('/chart-data', requestBody);
            console.log("Response Data: ", response);
            console.log("Response Filter Data: ", response.data.message);
            if (response && response.data && response.data.message) {
                if (response.data.message.length > 0) {
                    if (response.data.status == "success") {
                        setFilterSystemsData(response.data.message);
                    }
                } else {
                    setFilterSystemsData([]);
                }
            } else {
                setFilterSystemsData([]);
            }
        } catch (err) {
            setFiltertLoading(false);

        } finally {
            setFiltertLoading(false);
        }
    }

    // Handler for adding input values
    const handlePackageChange = (e) => {
        const value = e.target.value;
        setFormData((prev) => ({
            ...prev,
            Package: {
                ...prev.Package,
                filter_options: value ? [value] : [],
            },
        }));
    };

    const handleArtifactsChange = (e) => {
        const value = e.target.value;
        setFormData((prev) => ({
            ...prev,
            Artifacts: {
                ...prev.Artifacts,
                filter_options: value ? [value] : [],
            },
        }));
    };
    const handleFocus = () => {
        const dropdown = document.getElementById('input-dropdown-1');
        dropdown.style.display = 'block';
    };

    const handleBlur = (e) => {
        setTimeout(() => {
            if (!dropdownRef.current.contains(e.relatedTarget)) {
                const dropdown = document.getElementById('input-dropdown-1');
                dropdown.style.display = 'none';
            }
        }, 200);
    };

    const filteredData = filterSystemsData.filter((item) =>
        item.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const packageData = filterPackageData.filter((item) =>
        item.toLowerCase().includes(searchPackageQuery.toLowerCase())
    );
    const artifactsData = filterArtifactsData.filter((item) =>
        item.toLowerCase().includes(searchArtifactsQuery.toLowerCase())
    );
    // Searching feature ends here

    const [formData, setFormData] = React.useState({
        Systems: { type: "", filter_options: [], checked: false },
        Package: { filter_options: [], checked: false },
        Artifacts: { filter_options: [], checked: false },
    });

    useEffect(() => {
        if (chartloading) return; // Skip chart rendering when loading

        const chartDom = document.getElementById('messagingprocessing');
        if (!chartDom) return;
        const myChart = echarts.init(chartDom);

        const updateChart = () => {
            myChart.setOption({
                color: ["#91CC75", "#FAC858", "#EE6666", "#2ecc71", "#9b59b6"],
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    orient: 'horizontal',
                    left: 'left',
                    top: '-2%',
                    textStyle: {
                        fontSize: 10,
                        color: '#333',
                        zIndex: '999',
                    },
                    itemWidth: 10,
                    itemHeight: 10,
                    padding: [5, 5], // Add padding for better spacing
                    formatter: (name) => {
                        // Find the corresponding value from responseData
                        const item = responseData.find((data) => data.Status === name);
                        return item ? `${item.count}` : name;
                    },

                },
                grid: {
                    top: '0%', // Increase space between legend and chart
                    bottom: '0%',
                },
                series: [
                    // Donut chart (conditionally rendered)
                    ...(chartVisible
                        ? [
                            {
                                // name: 'Donut Chart',
                                type: 'pie',
                                radius: ['70%', '90%'], // Inner and outer radius
                                label: {
                                    position: 'inside',
                                    formatter: '{c}',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: '#000',
                                },
                                labelLine: {
                                    show: false, // Hide label lines for the donut chart
                                },
                                data: [
                                    { value: 1048, name: 'Search Engine' },
                                    { value: 735, name: 'Direct' },
                                    { value: 580, name: 'Email' },
                                    { value: 484, name: 'Union Ads' },
                                    { value: 300, name: 'Video Ads' },
                                ],
                                itemStyle: {
                                    opacity: 0.7, // Slight transparency for the donut chart
                                },
                            },
                        ]
                        : []),
                    // Overlapping pie chart (always visible)
                    {
                        // name: 'Pie Chart',
                        type: 'pie',
                        radius: !chartVisible ? '90%' : '55%', // Smaller pie chart
                        label: {
                            position: 'inside',
                            formatter: (params) => `${Math.round(params.percent)}`,
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: '#333',
                        },
                        data: responseData.map((item) => ({
                            value: item.count,
                            name: item.Status,
                        })),
                    },
                ],
            });
        };

        updateChart(); // Set initial chart options

        return () => {
            myChart.dispose(); // Clean up on unmount
        };
    }, [chartVisible, chartloading, responseData]);




    const formDataCollectionhandler = () => {
        setShowViewModal(false);
        setChartVisible(true);
        console.log("Formdata collected: ", formData);
    }

    // Handler to reset selected items and search box
    const handleReset = () => {
        setSelectedItems([]); // Clear selected items
        // Reset all checkboxes
        setFormData((prevData) => {
            const updatedData = {};
            for (const key in prevData) {
                updatedData[key] = {
                    ...prevData[key],
                    checked: false, // Set checked to false
                };
            }
            return updatedData;
        });
    };




    const fetchData = async (startTime, endTime) => {
        setChartLoading(true);
        console.log("User Time Zone: ", systemTimeZone);
        const requestBody = {
            'ActionType': 'message_processing',
            'CustomerID': sessionStorage.getItem('CustomerID'),
            'conditions': formData,
            'Start': startTime,
            'End': endTime
        };

        try {
            console.log("Request Data: ", requestBody);
            const response = await AxiosInstance.post('/chart-data', requestBody); // Adjust the path to your API
            console.log("Response Data: ", response);
            if (response && response.data && response.data.message) {
                if (response.data.message.length > 0) {
                    if (response.data.status == "success") {
                        setResponseData(response.data.message);            //         } else {
                    }
                } else {
                    setResponseData([]);
                }
            } else {
                setResponseData([]);
            }
        } catch (err) {


        } finally {
            setChartLoading(false);
        }
    };
    useEffect(() => {
        const currentUserDateTime = getChartTimeDropDown(getTimeZone(systemTimeZone), timeRange);
        console.log("Default time zone Datetime", currentUserDateTime);
        console.log("Default time zone Start", currentUserDateTime.startUTC);
        console.log("Default time zone End", currentUserDateTime.endUTC);

        fetchData(currentUserDateTime.startUTC, currentUserDateTime.endUTC);

    }, [timeRange]);
    const handleTimeRangeChange = (e) => {
        setTimeRange(e.target.value);
    };
    return (
        <>
            <Modal className="custom-modal-end" show={showViewModal} onHide={handleCloseViewModal} centered backdrop="static" keyboard={false}>
                <div style={{ position: 'relative' }}>
                    <button
                        className="custom-modal-close-btn m-1"
                        onClick={handleCloseViewModal}
                        aria-label="Close"
                    >
                        &times; {/* Close icon */}
                    </button>
                </div>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: '16px' }}>Message Processing Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className="filter-custom-modal-body"                >
                    {/* Grid Container */}
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1.3fr 2.2fr',
                            gap: '4px', // Space between columns and rows
                            padding: '2px', // Padding inside the grid container
                        }}
                    >
                        {/* Row 1 */}
                        <div style={{ textAlign: 'left' }}>
                            <input
                                type="checkbox"
                                id="systems"
                                name="systems"
                                marginBottom="0px"
                                cursor="pointer"
                                onChange={(e) => handleCheckboxChange("Systems", e.target.checked)}
                            />
                            <label htmlFor="checkbox-1" style={{ fontSize: '14px', }}>Systems</label>
                        </div>


                        <div style={{ textAlign: 'center' }}>
                            <select
                                value={formData.Systems?.type || "0"}
                                onChange={(e) => handleTypeChange("Systems", e.target.value)}
                                style={{
                                    width: '100%',
                                    padding: '4px',
                                    fontSize: '13px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                    marginBottom: "0px",
                                    cursor: formData.Systems?.checked ? "pointer" : "not-allowed",
                                    backgroundColor: formData.Systems?.checked ? "white" : "#f2f2f2"
                                }}
                            >
                                <option value="0">Select</option>
                                <option value="Sender/Receiver">Sender/Receiver</option>
                                <option value="Only_Sender">Only Sender</option>
                                <option value="Only_Receiver">Only Receiver</option>
                            </select>
                        </div>
                        <div style={{ position: 'relative' }}>
                            {filterloading ? (<div style={{ textAlign: 'center' }}>
                                <span
                                    className="spinner-border spinner-border-sm text-primary"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: '10px' }}
                                ></span>
                            </div>) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        position: 'relative',
                                        overflowX: 'auto',
                                        width: '220px', // Fixed width for the input container
                                        height: '27px', // Fixed height for consistency
                                        scrollbarWidth: 'none',
                                    }}
                                    onClick={() => inputRef.current.focus()}
                                >
                                    {selectedItems.map((item, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                backgroundColor: '#b2dfdb',
                                                borderRadius: '5px',
                                                padding: '1px 3px',
                                                margin: '2px',
                                                fontSize: '12px',
                                                flexShrink: 0, // Prevent shrinking of tags
                                            }}
                                        >
                                            {item}{' '}
                                            <span
                                                style={{
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                    marginLeft: '5px',
                                                }}
                                                onClick={() => handleItemRemove(item)}
                                            >
                                                &times;
                                            </span>
                                        </span>
                                    ))}

                                    <input
                                        ref={inputRef}
                                        type="text"
                                        className='filter-custom-input'
                                        placeholder={selectedItems.length === 0 ? 'Search systems' : ''}
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        style={{
                                            flex: '1',
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: '12px',
                                            padding: '4px',
                                            minWidth: '50px',
                                            flexShrink: 0, // Prevent shrinking of input
                                            minWidth: '50px', // Minimum width for visibility
                                        }}
                                    />

                                </div>
                            )}
                            <div
                                ref={dropdownRef}
                                id="input-dropdown-1"
                                style={{
                                    display: 'none', // Initially hidden
                                    position: 'absolute',
                                    top: '100%', // Positioned below the input
                                    left: '0',
                                    width: '100%',
                                    backgroundColor: '#e0f7fa', // Light color background
                                    padding: '8px',
                                    borderRadius: '5px',
                                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                    fontSize: '12px',
                                    marginTop: '0px',
                                    zIndex: '999', // Make sure it appears above other elements
                                    height: '150px', // Adjusted height
                                    overflowY: 'auto', // Allows scrolling if content overflows
                                    scrollbarWidth: 'none',
                                }}
                            >
                                {filteredData.length > 0 ? (
                                    filteredData.map((item, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                padding: '6px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleItemSelect(item)}
                                        >
                                            {item}
                                        </div>
                                    ))
                                ) : (
                                    <div style={{ padding: '6px', color: 'gray' }}>No results found</div>
                                )}
                            </div>
                            {selectedItems.length > 0 ? (<i
                                className="fa fa-refresh"
                                aria-hidden="true"
                                onClick={handleReset}
                                style={{
                                    position: 'absolute',
                                    top: '-6px',
                                    right: '-4px',
                                    fontSize: '14px',
                                    color: '#007bff',
                                    cursor: 'pointer',
                                }}
                            ></i>) : ('')}

                        </div>

                        {/* Row 2 */}
                        <div style={{ textAlign: 'left' }}>
                            <input
                                type="checkbox"
                                id="package"
                                name="package"
                                fontSize="12px"
                                onChange={(e) => handleCheckboxChange("Package", e.target.checked)}
                            />
                            <label htmlFor="checkbox-2" style={{ fontSize: '14px', }}>Package</label>
                        </div>

                        {/* Row 2 Select Box */}
                        {packageloading ? (<div style={{ gridColumn: 'span 2' }}>
                            <span
                                className="spinner-border spinner-border-sm text-primary"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '10px' }}
                            ></span>
                        </div>) : (
                            <div style={{ gridColumn: 'span 2', position: 'relative' }}>
                                {selectedPackageItems.map((item, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            backgroundColor: '#b2dfdb',
                                            borderRadius: '5px',
                                            padding: '1px 3px',
                                            margin: '2px',
                                            fontSize: '12px',
                                            flexShrink: 0, // Prevent shrinking of tags
                                        }}
                                    >
                                        {item}{' '}
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                color: 'red',
                                                marginLeft: '5px',
                                            }}
                                            onClick={() => handleItemRemove(item)}
                                        >
                                            &times;
                                        </span>
                                    </span>
                                ))}
                                <input
                                    type="text"
                                    className='filter-custom-input'
                                    placeholder="Search package"
                                    onChange={handlePackageChange}
                                    style={{
                                        width: '100%',
                                        padding: '4px 8px', // Decreased padding for smaller height
                                        fontSize: '12px', // Smaller font size
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                    }}
                                    onFocus={(e) => {
                                        const dropdown = document.getElementById('input-dropdown-2');
                                        dropdown.style.display = 'block';
                                    }}
                                    onBlur={(e) => {
                                        const dropdown = document.getElementById('input-dropdown-2');
                                        dropdown.style.display = 'none';
                                    }}
                                />
                                <div
                                    id="input-dropdown-2"
                                    style={{
                                        display: 'none', // Initially hidden
                                        position: 'absolute',
                                        top: '100%', // Positioned below the input
                                        left: '0',
                                        width: '100%',
                                        backgroundColor: '#e0f7fa', // Light color background
                                        padding: '8px',
                                        borderRadius: '5px',
                                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                        fontSize: '12px',
                                        marginTop: '0px',
                                        zIndex: '999', // Make sure it appears above other elements
                                        height: '100px', // Increased height
                                        overflowY: 'auto', // Allows scrolling if content overflows
                                    }}
                                >
                                    {/* Content for the dropdown */}
                                    {packageData.length > 0 ? (
                                        packageData.map((item, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    padding: '6px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => handleItemSelect(item)}
                                            >
                                                {item}
                                            </div>
                                        ))
                                    ) : (
                                        <div style={{ padding: '6px', color: 'gray' }}>No results found</div>
                                    )}
                                </div>
                                {selectedPackageItems.length > 0 ? (<i
                                    className="fa fa-refresh"
                                    aria-hidden="true"
                                    onClick={handleReset}
                                    style={{
                                        position: 'absolute',
                                        top: '-6px',
                                        right: '-4px',
                                        fontSize: '14px',
                                        color: '#007bff',
                                        cursor: 'pointer',
                                    }}
                                ></i>) : ('')}
                            </div>
                        )}




                        {/* Row 3 */}
                        <div style={{ textAlign: 'left' }}>
                            <input
                                type="checkbox"
                                id="artifacts"
                                name="artifacts"
                                onChange={(e) => handleCheckboxChange("Artifacts", e.target.checked)}
                            />
                            <label htmlFor="checkbox-3" style={{ fontSize: '14px', }}>Artifacts</label>
                        </div>

                        {/* Row 3 Select Box */}
                        {artifactsloading ? (<div style={{ gridColumn: 'span 2', position: 'relative' }}>
                            <span
                                className="spinner-border spinner-border-sm text-primary"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '10px' }}
                            ></span>
                        </div>) : (
                            <div style={{ gridColumn: 'span 2' }}>
                                <input
                                    type="text"
                                    className='filter-custom-input'
                                    placeholder="Search artifacts"
                                    onChange={handleArtifactsChange}
                                    style={{
                                        width: '100%',
                                        padding: '4px 8px', // Decreased padding for smaller height
                                        fontSize: '12px', // Smaller font size
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                    }}
                                />
                            </div>
                        )}

                        {/* Row 3 Text Input */}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewModal} style={{
                        padding: '2px 8px', // Adjust padding for smaller height and width
                        fontSize: '14px', // Reduce font size
                        lineHeight: '1.7', // Reduce line height
                    }} >
                        Close
                    </Button>
                    <Button variant="primary" style={{
                        padding: '2px 8px', // Adjust padding for smaller height and width
                        fontSize: '14px', // Reduce font size
                        lineHeight: '1.7', // Reduce line height
                    }} onClick={formDataCollectionhandler}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal >

            <div className="card" style={{ width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="card-body d-flex flex-column align-items-center">
                    {chartloading ? (
                        <span
                            className="spinner-border spinner-border-lg text-primary"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: '10px' }}
                        ></span>
                    ) : (
                        <>
                            {responseData && responseData.length > 0 ? (
                                <div
                                    id="messagingprocessing"
                                    style={{ minHeight: "210px", width: "200px", overflow: "visible" }}
                                    className="echart"
                                ></div>
                            ) : (
                                <div
                                    style={{
                                        minHeight: "200px",
                                        width: "200px",
                                        marginTop: "-20px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        color: "#ff6b6b", // Soft red color
                                        fontWeight: "normal",
                                    }}
                                >
                                    <p>No data available</p></div>
                            )}
                            {/* <div
                                id="messagingprocessing"
                                style={{ minHeight: '200px', width: '200px', marginTop: '-20px' }}
                                className="echart"
                            ></div> */}

                            <div className="row" style={{
                                display: 'flex',  // Flexbox layout
                                justifyContent: 'space-between',  // Distribute space between the select and button
                                width: '100%',  // Full width for the container
                                alignItems: 'center',  // Vertically align items
                                gap: '4px',  // Space between the select and button
                                paddingLeft: '6px',
                                paddingRight: '6px',
                            }}>
                                {/* <select className="testing-custom-chart-dropdown" style={{ flex: '1' }}>
                                    {dropdownOptions.map((option) => (
                                        <option key={option} value={option.toLowerCase()}>
                                            {option}
                                        </option>
                                    ))}
                                </select> */}
                                <select className="testing-custom-chart-dropdown" style={{ flex: '1' }}
                                    value={timeRange} // Set default value
                                    onChange={handleTimeRangeChange} // Handle change to update the state
                                >
                                    {dropdownOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                <Button
                                    variant={!chartVisible ? "outline-primary" : "warning"}
                                    size="sm"
                                    // onClick={() => setDonutChartVisible((prev) => !prev)}
                                    onClick={handleViewClick}
                                    style={{
                                        flex: '1',  // Make select box expand to take available space
                                        height: '26px',  // Set height to match button
                                        fontSize: '12px',  // Smaller font for compact appearance
                                    }}
                                >
                                    Custom chart
                                </Button>

                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default MessageProcessingChart