import React, { useEffect, useState } from 'react'
import './sideBar.css';
import { Link } from 'react-router-dom';
import Monitoring from '../../Resource/Monitoring.png';
import SecurityAudit from '../../Resource/SecurityAudit.png';
import Settings from '../../Resource/Settings.png';
import EnvelopeImage from '../../Resource/envelope-image.png';
import AxiosInstance from '../../Components/RestApi/AxiosInstance';

function SideBar({ onSidebarItemClick }) {
  const [isSidebarLoading, setIsSidebarLoading] = useState(false);
  const [userSessionData, setUserSessionData] = useState([]);

  useEffect(() => {
    setIsSidebarLoading(false);
    fetchCurrentUserDataAPI();

  }, []);


  const setCurrentUserSessionData = (response) => {
    if (response && response.data && response.data.message) {
      //console.log("Sidebar Get Data: ", response.data.message);

      // Save the data in a variable
      const sessionData = response.data.message;

      // Store data in sessionStorage
      if (sessionData.CustomerDetails) {
        // sessionStorage.setItem('SystemTimeZone', sessionData.CustomerDetails.SystemTimeZone || '');
        sessionStorage.setItem('CustomerAddress', sessionData.CustomerDetails.CustomerAddress || '');
        sessionStorage.setItem('CompanyLogo', sessionData.CustomerDetails.CompanyLogo || '');
      } else {
        //console.error("CustomerDetails is missing in the response.");
      }

      if (sessionData.UserRoles) {
        sessionStorage.setItem('UserRoleID', sessionData.UserRoles.RoleID || '');
      } else {
        // console.error("UserRoles is missing in the response.");
      }

      if (sessionData.UserPermissions) {
        sessionStorage.setItem('UserPermissions', JSON.stringify(sessionData.UserPermissions) || '[]');
      } else {
        //console.error("UserPermissions is missing in the response.");
      }

      // Log successful storage
      //console.log("User session data saved successfully.");
    } else {
      //console.error("Empty or invalid response.");
    }
  }

  const fetchCurrentUserDataAPI = async () => {

    const requestBody = { 'ActionType': 'get_user_details', 'CustomerID': sessionStorage.getItem('CustomerID') };
    try {
      // console.log("Sidebar Request Data: ", requestBody);
      const response = await AxiosInstance.post('/manage-resource', requestBody); // Adjust the path to your API
      //console.log("Sidebar Response Data: ", response);
      if (response && response.data && response.data.message) {
        //console.log("Sidebar  Current User data: ", response.data.message);
        setUserSessionData(response.data.message);
        setCurrentUserSessionData(response);
      } else {
        //console.log("Empty or invalid response.");
      }

      ///setCurrentUserSessionData(userSessionData);

      //console.log("Current User data: ", userSessionData.CustomerDetails.CustomerAddress);
      // sessionStorage.setItem('SystemTimeZone', userSessionData.CustomerDetails.SystemTimeZone);
      // sessionStorage.setItem('CustomerAddress', userSessionData.CustomerDetails.CustomerAddress);
      // sessionStorage.setItem('CompanyLogo', userSessionData.CustomerDetails.CompanyLogo);

      // sessionStorage.setItem('UserRoleID', userSessionData.UserRoles.RoleID);
      // sessionStorage.setItem('UserPermissions', userSessionData.UserPermissions);
    } catch (err) {
      //  console.error('Error fetching server zones:', err); // Log the full error for more details
    } finally {
      setIsSidebarLoading(false);

    }
  };


  const handleSidebarItemClick = () => {
    // Call the function passed via props to handle resetting
    if (onSidebarItemClick) onSidebarItemClick();
  };

  return (

    <aside id="dashboard-sidebar" className="dashboard-sidebar">
      <ul className="dashboard-sidebar-nav" id="dashboard-sidebar-nav">
        {isSidebarLoading ? (
          <div className="w-100 p-4 shadow rounded bg-light destination-tb-custom-border"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <span
              className="spinner-border spinner-border-lg text-primary"
              role="status"
              aria-hidden="true"
            ></span>
          </div>

        ) : (
          <>
            <li className="nav-item">
              <Link to="/overview" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-grid"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            <li className="nav-item">
              <a
                className="nav-link collapsed"
                data-bs-target="#components-nav"
                data-bs-toggle="collapse"
                href="#"
              >
                <img src={Monitoring} alt="Monitoring Icon" className="sidebar-icon" />
                <span>Monitoring</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="components-nav" className="nav-content collapse" data-bs-parent="#dashboard-sidebar-nav">

                <li className="nav-heading">Messages</li>
                <li>
                  <Link to="/monitoring/overall" className="nav-link" onClick={handleSidebarItemClick}>
                    <i className="bi bi-circle"></i>
                    <span>Overview</span>
                  </Link>
                </li>
                <li>
                  <Link to="/monitoring/failure-retry" className="nav-link" onClick={handleSidebarItemClick}>
                    <i className="bi bi-circle"></i>
                    <span>Failure-Retry</span>
                  </Link>
                </li>
                <li>
                  <Link to="/edit-payload" className="nav-link" onClick={handleSidebarItemClick}>
                    <i className="bi bi-circle"></i>
                    <span>Payload</span>
                  </Link>
                </li>
              </ul>
              <ul id="components-nav" className="nav-content collapse" data-bs-parent="#dashboard-sidebar-nav">
                <li className="nav-heading">Jobs</li>
                <li>
                  <Link to="/monitoring/jobs-overall" className="nav-link" onClick={handleSidebarItemClick}>
                    <i className="bi bi-circle"></i>
                    <span>Overview</span>
                  </Link>
                </li>
                <li>
                  <Link to="/monitoring/jobs-failure" className="nav-link" onClick={handleSidebarItemClick}>
                    <i className="bi bi-circle"></i>
                    <span>Failure</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <a
                className="nav-link collapsed"
                data-bs-target="#forms-nav"
                data-bs-toggle="collapse"
                href="#"
              >
                <img src={SecurityAudit} alt="Security Audit Icon" className="sidebar-icon" />
                <span>Security & Audit</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="forms-nav" className="nav-content collapse" data-bs-parent="#dashboard-sidebar-nav">
                <li className="nav-heading">Users</li>
                <li>
                  <Link to="/security/users" className="nav-link" onClick={handleSidebarItemClick}>
                    <i className="bi bi-circle"></i>
                    <span>Users</span>
                  </Link>
                </li>
                <li className="nav-heading">Audit</li>
                <li>
                  <Link to="/security/system" className="nav-link" onClick={handleSidebarItemClick}>
                    <i className="bi bi-circle"></i>
                    <span>System</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <a
                className="nav-link collapsed"
                data-bs-target="#tables-nav"
                data-bs-toggle="collapse"
                href="#"
              >
                <img src={Settings} alt="Settings Icon" className="sidebar-icon" />
                <span>Settings</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="tables-nav" className="nav-content collapse" data-bs-parent="#dashboard-sidebar-nav">
                <li>
                  <Link to="/branding" className="nav-link" onClick={handleSidebarItemClick}>
                    <i className="bi bi-circle"></i>
                    <span>Branding & Features</span>
                  </Link>
                </li>
                {/* <li>
              <Link to="/features" className="nav-link" onClick={handleSidebarItemClick}>
                <i className="bi bi-circle"></i>
                <span>Features</span>
              </Link>
            </li> */}
                <li className="nav-heading">Connectivity</li>
                <li>
                  <Link to="/connectivity/destination" className="nav-link" onClick={handleSidebarItemClick}>
                    <i className="bi bi-circle"></i>
                    <span>Destination</span>
                  </Link>
                </li>
                <li>
                  <Link to="/connectivity/endpoint" className="nav-link" onClick={handleSidebarItemClick}>
                    <i className="bi bi-circle"></i>
                    <span>Endpoint</span>
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li className="nav-item">
              <a
                className="nav-link collapsed"
                data-bs-target="#components-nav"
                data-bs-toggle="collapse"
                href="#"
              >
                <img src={Monitoring} alt="Payload Icon" className="sidebar-icon" />
                <span>Payload</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul>
                <li>
                  <Link to="/edit-payload" className="nav-link" onClick={handleSidebarItemClick}>
                    <i className="bi bi-circle"></i>
                    <span>Payload1</span>
                  </Link>
                </li>
              </ul>
            </li> */}
            {/* <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
              >
                <img src={EnvelopeImage} alt="Payload Icon" className="sidebar-icon" />
                <span>Payload</span>
                <Link to="/edit-payload" className="nav-link" onClick={handleSidebarItemClick}></Link>

              </a>
            </li> */}
            {/* <li className="nav-item">
              <Link to="/edit-payload" className="nav-link collapsed" onClick={handleSidebarItemClick}>
                <img src={EnvelopeImage} alt="Payload Icon" className="sidebar-icon" />
                <span>Payload</span>
              </Link>
            </li> */}
          </>
        )}
      </ul>
    </aside>


  );
}

export default SideBar;
