import React, { useState } from 'react';
import Chart from 'react-apexcharts';

function MessageMonitoringReport() {
    const dropdownOptions = ["Daily", "Monthly", "Yearly"];
    const years = ["2020", "2021", "2022", "2023", "2024", "2025"];
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];


    // const [data, setData] = useState({
    //     series: [
    //       {
    //         name: 'Sales',
    //         data: [31, 40, 28, 51, 42, 82, 56],
    //       },
    //       {
    //         name: 'Revenue',
    //         data: [11, 32, 45, 32, 34, 52, 41],
    //       },
    //       {
    //         name: 'Customers',
    //         data: [15, 11, 32, 18, 9, 24, 11],
    //       },
    //     ],
    //     options: {
    //       chart: {
    //         height: 350,
    //         type: 'area',
    //         toolbar: {
    //           show: true, 
    //         },
    //         zoom: {
    //           enabled: true, 
    //         },
    //         animations: {
    //           enabled: false,
    //         },
    //       },
    //       markers: {
    //         size: 4,
    //       },
    //       colors: ['#4154f1', '#2eca6a', '#ff771d'],
    //       fill: {
    //         type: 'gradient',
    //         gradient: {
    //           shadeIntensity: 1,
    //           opacityFrom: 0.3,
    //           opacityTo: 0.4,
    //           stops: [0, 90, 100],
    //         },
    //       },
    //       dataLabels: {
    //         enabled: false,
    //       },
    //       stroke: {
    //         curve: 'smooth',
    //         width: 2,
    //       },
    //       xaxis: {
    //         type: 'datetime',
    //         categories: [
    //           '2018-09-19T00:00:00.000Z',
    //           '2018-09-19T01:30:00.000Z',
    //           '2018-09-19T02:30:00.000Z',
    //           '2018-09-19T03:30:00.000Z',
    //           '2018-09-19T04:30:00.000Z',
    //           '2018-09-19T05:30:00.000Z',
    //           '2018-09-19T06:30:00.000Z',
    //         ],
    //       },
    //       tooltip: {
    //         x: {
    //           format: 'dd/MM/yy HH:mm',
    //         },
    //       },
    //       legend: {
    //         position: 'top',
    //         horizontalAlign: 'left',
    //         floating: true,
    //         offsetX: 10,
    //         offsetY: 10,
    //       },
    //       xaxis: {
    //         type: 'datetime',
    //         labels: {
    //           format: 'dd/MM/yyyy HH:mm',
    //         },
    //       },
    //       chart: {
    //         toolbar: {
    //           show: true, 
    //         },
    //         zoom: {
    //           enabled: true,
    //         },
    //         pan: {
    //           enabled: true,
    //           mode: 'x', 
    //         },
    //       },
    //     },
    //   });

    // const [data, setData] = useState({
    //     series: [
    //         {
    //             name: 'Sales',
    //             data: [31, 40, 28, 51, 42, 82, 56],
    //         },
    //         {
    //             name: 'Revenue',
    //             data: [11, 32, 45, 32, 34, 52, 41],
    //         },
    //         {
    //             name: 'Customers',
    //             data: [15, 11, 32, 18, 9, 24, 11],
    //         },
    //     ],
    //     options: {
    //         chart: {
    //             height: 350,
    //             type: 'area',
    //             toolbar: {
    //                 show: false,
    //             },
    //         },
    //         markers: {
    //             size: 4,
    //         },
    //         colors: ['#4154f1', '#2eca6a', '#ff771d'],
    //         fill: {
    //             type: 'gradient',
    //             gradient: {
    //                 shadeIntensity: 1,
    //                 opacityFrom: 0.3,
    //                 opacityTo: 0.4,
    //                 stops: [0, 90, 100],
    //             },
    //         },
    //         dataLabels: {
    //             enabled: false,
    //         },
    //         stroke: {
    //             curve: 'smooth',
    //             width: 2,
    //         },
    //         xaxis: {
    //             type: 'datetime',
    //             categories: [
    //                 '2018-09-19T00:00:00.000Z',
    //                 '2018-09-19T01:30:00.000Z',
    //                 '2018-09-19T02:30:00.000Z',
    //                 '2018-09-19T03:30:00.000Z',
    //                 '2018-09-19T04:30:00.000Z',
    //                 '2018-09-19T05:30:00.000Z',
    //                 '2018-09-19T06:30:00.000Z',
    //             ],
    //         },
    //         tooltip: {
    //             x: {
    //                 format: 'dd/MM/yy HH:mm',
    //             },
    //         },
    //         legend: {
    //             position: 'top',
    //             horizontalAlign: 'left',
    //             floating: true,
    //             offsetX: 0,
    //             offsetY: 0,
    //         },
    //     },
    // });

    // const [data, setData] = useState({
    //     series: [
    //         {
    //             name: 'Success',
    //             data: [31, 40, 28, 51, 42, 82, 56],
    //         },
    //         {
    //             name: 'Failure',
    //             data: [11, 32, 45, 32, 34, 52, 41],
    //         }
    //     ],
    //     options: {
    //         chart: {
    //             height: 250,
    //             type: 'area',
    //             toolbar: {
    //                 show: false,
    //             },
    //         },
    // title: {
    //     text: 'Message Monitoring',
    //     align: 'left',
    //     offsetX: 10,
    //     offsetY: -3,
    //     floating: true,
    //     style: {
    //         fontSize: '16px',
    //         fontWeight: 'normal',
    //         color: '#333',
    //     },
    // },
    //         legend: {
    //             position: 'top',
    //             horizontalAlign: 'left',
    //             floating: true,
    //             offsetX: 140,
    //             offsetY: 2,
    //             itemMargin: {
    //                 horizontal: 3,
    //                 vertical: 0,
    //             },
    //             customLegendItems: false,
    //         },
    //         markers: {
    //             size: 4,
    //         },
    //         colors: ['#2eca6a', '#ff771d', '#ff771d'],
    //         fill: {
    //             type: 'gradient',
    //             gradient: {
    //                 shadeIntensity: 1,
    //                 opacityFrom: 0.3,
    //                 opacityTo: 0.4,
    //                 stops: [0, 90, 100],
    //             },
    //         },
    //         dataLabels: {
    //             enabled: false,
    //         },
    //         stroke: {
    //             curve: 'straight',
    //             width: 2,
    //         },
    //         xaxis: {
    //             type: 'datetime',
    //             categories: [
    //                 '2018-09-19T00:00:00.000Z',
    //                 '2018-09-19T01:30:00.000Z',
    //                 '2018-09-19T02:30:00.000Z',
    //                 '2018-09-19T03:30:00.000Z',
    //                 '2018-09-19T04:30:00.000Z',
    //                 '2018-09-19T05:30:00.000Z',
    //                 '2018-09-19T06:30:00.000Z',
    //             ],
    //         },
    //         tooltip: {
    //             x: {
    //                 format: 'dd/MM/yy HH:mm',
    //             },
    //         },
    //     },
    // });

    const [data, setData] = useState({
        series: [
            {
                name: 'Success',
                data: [31, 40, 28, 51, 42, 82, 56],
            },
            {
                name: 'Failure',
                data: [11, 32, 45, 32, 34, 52, 41],
            },

        ],
        options: {
            chart: {
                height: 250,
                type: 'area',
                toolbar: {
                    show: false,
                },
            },
            markers: {
                size: 4,
            },
            colors: ['#2eca6a', '#ff771d'],
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.3,
                    opacityTo: 0.4,
                    stops: [0, 90, 100],
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'straight',
                width: 2,
            },
            xaxis: {
                type: 'datetime',
                categories: [
                    '2018-09-19T00:00:00.000Z',
                    '2018-09-19T01:30:00.000Z',
                    '2018-09-19T02:30:00.000Z',
                    '2018-09-19T03:30:00.000Z',
                    '2018-09-19T04:30:00.000Z',
                    '2018-09-19T05:30:00.000Z',
                    '2018-09-19T06:30:00.000Z',
                ],
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm',
                },
            },
            title: {
                text: 'Message Monitoring',
                align: 'left',
                offsetX: 10,
                offsetY: -6,
                style: {
                    fontSize: '16px',
                    fontWeight: 'normal',
                    color: '#333',
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: -16,
                offsetY: -20,
            },
        },
    });

    return (
        // <div className="card">
        //     <div className="card-body">

        //             <select className="testing-custom-chart-dropdown" style={{ flex: '1' }}>
        //                 {dropdownOptions.map((option) => (
        //                     <option key={option} value={option.toLowerCase()}>
        //                         {option}
        //                     </option>
        //                 ))}
        //             </select>

        //         <Chart
        //             options={data.options}
        //             series={data.series}
        //             type={data.options.chart.type}
        //             height={data.options.chart.height}
        //         />
        //     </div>
        // </div>
        <div className="card">
            <div className="card-body" style={{ position: 'relative', padding: '20px' }}>
                {/* Select box */}
                <select
                    className="testing-custom-chart-dropdown"
                    style={{
                        position: 'absolute',
                        top: '14px',
                        right: '10px',
                        width: '80px',
                        zIndex: 10, // Ensures it's above the chart
                    }}>
                    {years.map((option) => (
                        <option key={option} value={option.toLowerCase()}>
                            {option}
                        </option>
                    ))}
                </select>
                <select
                    className="testing-custom-chart-dropdown"
                    style={{
                        position: 'absolute',
                        top: '14px',
                        right: '95px',
                        width: '100px',
                        zIndex: 10, // Ensures it's above the chart
                        overflowY: 'auto', // Enables vertical scrolling
                        maxHeight: '120px', // Restricts the height of the dropdown
                    }}
                >
                    {months.map((month, index) => (
                        <option key={index} value={month.toLowerCase()}>
                            {month}
                        </option>
                    ))}
                </select>
                <select
                    className="testing-custom-chart-dropdown"
                    style={{
                        position: 'absolute',
                        top: '14px',
                        right: '200px',
                        width: '80px',
                        zIndex: 10, // Ensures it's above the chart
                    }}>
                    {dropdownOptions.map((option) => (
                        <option key={option} value={option.toLowerCase()}>
                            {option}
                        </option>
                    ))}
                </select>
                <Chart
                    options={data.options}
                    series={data.series}
                    type={data.options.chart.type}
                    height={data.options.chart.height}
                />

            </div>
        </div>

    );
}

export default MessageMonitoringReport;
