

import React, { useRef, useState, useEffect } from 'react';
import * as echarts from 'echarts';
import Select from 'react-select';
import { Modal, Button } from 'react-bootstrap';

function IdocProcessingChart() {
    const dropdownOptions = ["Daily", "Monthly"];
    const [showViewModal, setShowViewModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const [idocChartVisible, setIdocChartVisible] = useState(false);

    const dummyData = [
        'Apple',
        'Banana',
        'Orange',
        'Grapes',
        'Mango',
        'Pineapple',
        'Strawberry',
        'Blueberry',
        'Raspberry',
        'Watermelon',
        'Peach',
        'Plum',
        'Pomegranate',
    ];
    const handleSearchChange = (e) => setSearchQuery(e.target.value);
    const handleViewClick = () => {
        setSelectedItems([]);
        setShowViewModal(true); // Show the View modal
    };
    const handleCloseViewModal = () => {
        setShowViewModal(false);
    };
    const toggleChartVisibility = () => {
        setIdocChartVisible(prevState => !prevState); // Toggle visibility
    };

    const handleItemSelect = (item) => {
        if (!selectedItems.includes(item)) {
            setSelectedItems((prev) => [...prev, item]);
            setSearchQuery('');
            setFormData((prev) => ({
                ...prev,
                Systems: {
                    ...prev.Systems,
                    filter_options: [...prev.Systems.filter_options, item],
                },
            }));
        }
    };

    const handleItemRemove = (item) => {
        setSelectedItems((prev) => prev.filter((i) => i !== item));
        setFormData((prev) => ({
            ...prev,
            Systems: {
                ...prev.Systems,
                filter_options: prev.Systems.filter_options.filter((i) => i !== item),
            },
        }));
    };

    // Handler for checkbox change
    const handleCheckboxChange = (name, isChecked) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: {
                ...prevData[name],
                checked: isChecked,
            },
        }));
    };

    // Handler for select box change
    const handleTypeChange = (name, selectedType) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: {
                ...prevData[name],
                type: selectedType,
            },
        }));
    };

    // Handler for adding input values
    const handlePackageChange = (e) => {
        const value = e.target.value;
        setFormData((prev) => ({
            ...prev,
            Package: {
                ...prev.Package,
                filter_options: value ? [value] : [],
            },
        }));
    };

    const handleArtifactsChange = (e) => {
        const value = e.target.value;
        setFormData((prev) => ({
            ...prev,
            Artifacts: {
                ...prev.Artifacts,
                filter_options: value ? [value] : [],
            },
        }));
    };
    const handleFocus = () => {
        const dropdown = document.getElementById('input-dropdown-1');
        dropdown.style.display = 'block';
    };

    const handleBlur = (e) => {
        setTimeout(() => {
            if (!dropdownRef.current.contains(e.relatedTarget)) {
                const dropdown = document.getElementById('input-dropdown-1');
                dropdown.style.display = 'none';
            }
        }, 200);
    };

    const filteredData = dummyData.filter((item) =>
        item.toLowerCase().includes(searchQuery.toLowerCase())
    );
    // Searching feature ends here

    const [formData, setFormData] = React.useState({
        Systems: { type: "", filter_options: [], checked: false },
        Package: { filter_options: [], checked: false },
        Artifacts: { filter_options: [], checked: false },
    });

    useEffect(() => {
        const chartDom = document.getElementById('idocprocessing');
        const myChart = echarts.init(chartDom);
        const updateChart = () => {
            myChart.setOption({
                tooltip: {
                    trigger: 'item',
                },
                series: [
                    // Donut chart (conditionally rendered)
                    ...(idocChartVisible
                        ? [
                            {
                                // name: 'Donut Chart',
                                type: 'pie',
                                radius: ['70%', '90%'], // Inner and outer radius
                                label: {
                                    position: 'inside',
                                    formatter: '{c}',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: '#000',
                                },
                                labelLine: {
                                    show: false, // Hide label lines for the donut chart
                                },
                                data: [
                                    { value: 1048, name: 'Search Engine' },
                                    { value: 735, name: 'Direct' },
                                    { value: 580, name: 'Email' },
                                    { value: 484, name: 'Union Ads' },
                                    { value: 300, name: 'Video Ads' },
                                ],
                                itemStyle: {
                                    opacity: 0.7, // Slight transparency for the donut chart
                                },
                            },
                        ]
                        : []),
                    // Overlapping pie chart (always visible)
                    {
                        // name: 'Pie Chart',
                        type: 'pie',
                        radius: !idocChartVisible ? '90%' : '55%', // Smaller pie chart
                        label: {
                            position: 'inside',
                            formatter: '{c}',
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: '#333',
                        },
                        data: [
                            { value: 33, name: 'Category A' },
                            { value: 31, name: 'Category B' },
                            { value: 23, name: 'Category C' },
                            { value: 13, name: 'Category D' },
                            { value: 15, name: 'Category E' },
                        ],
                    },
                ],
            });
        };

        updateChart(); // Set initial chart options

        return () => {
            myChart.dispose(); // Clean up on unmount
        };
    }, [idocChartVisible]);

    const formDataCollectionhandler = () => {
        setShowViewModal(false);
        setIdocChartVisible(true);
        console.log("Formdata collected: ", formData);
    }

    // Handler to reset selected items and search box
    const handleReset = () => {
        setSelectedItems([]); // Clear selected items
        // Reset all checkboxes
        setFormData((prevData) => {
            const updatedData = {};
            for (const key in prevData) {
                updatedData[key] = {
                    ...prevData[key],
                    checked: false, // Set checked to false
                };
            }
            return updatedData;
        });
    };
    useEffect(() => {
        const chartDom = document.getElementById('idocprocessing');
        const myChart = echarts.init(chartDom);
        const updateChart = () => {
            myChart.setOption({
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    orient: 'horizontal',
                    left: 'left',
                    top: '-2%',
                    textStyle: {
                        fontSize: 10,
                        color: '#333',
                        zIndex: '999',
                    },
                    itemWidth: 10, // Adjust the marker size
                    itemHeight: 10, // Adjust the marker height
                    textStyle: {
                        fontSize: 12, // Font size for the legend labels
                        color: '#333', // Font color
                    },
                    formatter: (name) => {
                        // Get the corresponding series data and return the value for each name
                        const data = myChart.getOption().series[0].data; // Get data of the first series
                        const item = data.find((d) => d.name === name); // Find the item with matching name
                        return `${item.value}`; // Format it to show value along with name
                    },
                },
                series: [
                    // Donut chart (conditionally rendered)
                    ...(idocChartVisible
                        ? [
                            {
                                // name: 'Donut Chart',
                                type: 'pie',
                                radius: ['70%', '90%'], // Inner and outer radius
                                label: {
                                    position: 'inside',
                                    formatter: '{c}',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: '#000',
                                },
                                labelLine: {
                                    show: false, // Hide label lines for the donut chart
                                },
                                data: [
                                    { value: 1048, name: 'Search Engine' },
                                    { value: 735, name: 'Direct' },
                                    { value: 580, name: 'Email' },
                                    { value: 484, name: 'Union Ads' },
                                    { value: 300, name: 'Video Ads' },
                                ],
                                itemStyle: {
                                    opacity: 0.7, // Slight transparency for the donut chart
                                },
                            },
                        ]
                        : []),
                    // Overlapping pie chart (always visible)
                    {
                        // name: 'Pie Chart',
                        type: 'pie',
                        radius: !idocChartVisible ? '90%' : '55%', // Smaller pie chart
                        label: {
                            position: 'inside',
                            formatter: '{c}',
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: '#333',
                        },
                        data: [
                            { value: 33, name: 'Category A' },
                            { value: 31, name: 'Category B' },
                            { value: 23, name: 'Category C' },
                            { value: 13, name: 'Category D' },
                            { value: 15, name: 'Category E' },
                        ],
                    },
                ],
            });
        };

        updateChart(); // Set initial chart options

        return () => {
            myChart.dispose(); // Clean up on unmount
        };
    }, [idocChartVisible]);


    return (

        <>
            <Modal className="custom-modal-end" show={showViewModal} onHide={handleCloseViewModal} centered backdrop="static" keyboard={false}>
                <div style={{ position: 'relative' }}>
                    <button
                        className="custom-modal-close-btn m-1"
                        onClick={handleCloseViewModal}
                        aria-label="Close"
                    >
                        &times; {/* Close icon */}
                    </button>
                </div>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: '16px' }}>IDoc Processing Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className="filter-custom-modal-body"                >
                    {/* Grid Container */}
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1.3fr 2.2fr', // Three equal columns
                            gap: '4px', // Space between columns and rows
                            padding: '2px', // Padding inside the grid container
                        }}
                    >
                        {/* Row 1 */}
                        <div style={{ textAlign: 'left' }}>
                            <input
                                type="checkbox"
                                id="systems"
                                name="systems"
                                marginBottom="0px"
                                cursor="pointer"
                                onChange={(e) => handleCheckboxChange("Systems", e.target.checked)}
                            />
                            <label htmlFor="checkbox-1" style={{ fontSize: '14px', }}>Systems</label>
                        </div>

                        <div style={{ textAlign: 'center' }}>
                            <select
                                onChange={(e) => handleTypeChange("Systems", e.target.value)}
                                style={{
                                    width: '100%',
                                    padding: '4px',
                                    fontSize: '13px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                    marginBottom: "0px",
                                    cursor: "pointer"
                                }}
                            >
                                <option value="0">Select</option>
                                <option value="Sender/Receiver">Sender/Receiver</option>
                                <option value="Only_Sender">Only Sender</option>
                                <option value="Only_Receiver">Only Receiver</option>
                            </select>
                        </div>

                        <div style={{ position: 'relative' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    position: 'relative',
                                    overflowX: 'auto',
                                    width: '220px', // Fixed width for the input container
                                    height: '27px', // Fixed height for consistency
                                    scrollbarWidth: 'none',
                                }}
                                onClick={() => inputRef.current.focus()}
                            >
                                {selectedItems.map((item, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            backgroundColor: '#b2dfdb',
                                            borderRadius: '5px',
                                            padding: '1px 3px',
                                            margin: '2px',
                                            fontSize: '12px',
                                            flexShrink: 0, // Prevent shrinking of tags
                                        }}
                                    >
                                        {item}{' '}
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                color: 'red',
                                                marginLeft: '5px',
                                            }}
                                            onClick={() => handleItemRemove(item)}
                                        >
                                            &times;
                                        </span>
                                    </span>
                                ))}
                                <input
                                    ref={inputRef}
                                    type="text"
                                    className='filter-custom-input'
                                    placeholder={selectedItems.length === 0 ? 'Search systems' : ''}
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    style={{
                                        flex: '1',
                                        border: 'none',
                                        outline: 'none',
                                        fontSize: '12px',
                                        padding: '4px',
                                        minWidth: '50px',
                                        flexShrink: 0, // Prevent shrinking of input
                                        minWidth: '50px', // Minimum width for visibility
                                    }}
                                />
                            </div>

                            <div
                                ref={dropdownRef}
                                id="input-dropdown-1"
                                style={{
                                    display: 'none', // Initially hidden
                                    position: 'absolute',
                                    top: '100%', // Positioned below the input
                                    left: '0',
                                    width: '100%',
                                    backgroundColor: '#e0f7fa', // Light color background
                                    padding: '8px',
                                    borderRadius: '5px',
                                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                    fontSize: '12px',
                                    marginTop: '0px',
                                    zIndex: '999', // Make sure it appears above other elements
                                    height: '150px', // Adjusted height
                                    overflowY: 'auto', // Allows scrolling if content overflows
                                    scrollbarWidth: 'none',
                                }}
                            >
                                {filteredData.length > 0 ? (
                                    filteredData.map((item, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                padding: '6px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleItemSelect(item)}
                                        >
                                            {item}
                                        </div>
                                    ))
                                ) : (
                                    <div style={{ padding: '6px', color: 'gray' }}>No results found</div>
                                )}
                            </div>
                            {selectedItems.length > 0 ? (<i
                                className="fa fa-refresh"
                                aria-hidden="true"
                                onClick={handleReset}
                                style={{
                                    position: 'absolute',
                                    top: '-6px',
                                    right: '-4px',
                                    fontSize: '14px',
                                    color: '#007bff',
                                    cursor: 'pointer',
                                }}
                            ></i>) : ('')}

                        </div>

                        {/* Row 2 */}
                        <div style={{ textAlign: 'left' }}>
                            <input
                                type="checkbox"
                                id="package"
                                name="package"
                                fontSize="12px"
                                onChange={(e) => handleCheckboxChange("Package", e.target.checked)}
                            />
                            <label htmlFor="checkbox-2" style={{ fontSize: '14px', }}>Package</label>
                        </div>

                        {/* Row 2 Select Box */}
                        <div style={{ textAlign: 'center' }}>

                        </div>

                        {/* Row 2 Text Input */}
                        <div style={{ position: 'relative' }}>
                            <input
                                type="text"
                                className='filter-custom-input'
                                placeholder="Search package"
                                onChange={handlePackageChange}
                                style={{
                                    width: '100%',
                                    padding: '4px 8px', // Decreased padding for smaller height
                                    fontSize: '12px', // Smaller font size
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                                onFocus={(e) => {
                                    const dropdown = document.getElementById('input-dropdown-2');
                                    dropdown.style.display = 'block';
                                }}
                                onBlur={(e) => {
                                    const dropdown = document.getElementById('input-dropdown-2');
                                    dropdown.style.display = 'none';
                                }}
                            />
                            <div
                                id="input-dropdown-2"
                                style={{
                                    display: 'none', // Initially hidden
                                    position: 'absolute',
                                    top: '100%', // Positioned below the input
                                    left: '0',
                                    width: '100%',
                                    backgroundColor: '#e0f7fa', // Light color background
                                    padding: '8px',
                                    borderRadius: '5px',
                                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                    fontSize: '12px',
                                    marginTop: '0px',
                                    zIndex: '999', // Make sure it appears above other elements
                                    height: '100px', // Increased height
                                    overflowY: 'auto', // Allows scrolling if content overflows
                                }}
                            >
                                {/* Content for the dropdown */}
                                You can add helpful hints or options here.
                            </div>
                        </div>

                        {/* Row 3 */}
                        <div style={{ textAlign: 'left' }}>
                            <input
                                type="checkbox"
                                id="artifacts"
                                name="artifacts"
                                onChange={(e) => handleCheckboxChange("Artifacts", e.target.checked)}
                            />
                            <label htmlFor="checkbox-3" style={{ fontSize: '14px', }}>Artifacts</label>
                        </div>

                        {/* Row 3 Select Box */}
                        <div style={{ textAlign: 'center' }}>

                        </div>

                        {/* Row 3 Text Input */}
                        <div>
                            <input
                                type="text"
                                className='filter-custom-input'
                                placeholder="Search artifacts"
                                onChange={handleArtifactsChange}
                                style={{
                                    width: '100%',
                                    padding: '4px 8px', // Decreased padding for smaller height
                                    fontSize: '12px', // Smaller font size
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewModal} style={{
                        padding: '2px 8px', // Adjust padding for smaller height and width
                        fontSize: '14px', // Reduce font size
                        lineHeight: '1.7', // Reduce line height
                    }} >
                        Close
                    </Button>
                    <Button variant="primary" style={{
                        padding: '2px 8px', // Adjust padding for smaller height and width
                        fontSize: '14px', // Reduce font size
                        lineHeight: '1.7', // Reduce line height
                    }} onClick={formDataCollectionhandler}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal >
            <div className="card" style={{ width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="card-body d-flex flex-column align-items-center">

                    <div
                        id="idocprocessing"
                        style={{ minHeight: '210px', width: '200px' }}
                        className="echart"
                    ></div>
                    <div className="row" style={{
                        display: 'flex',  // Flexbox layout
                        justifyContent: 'space-between',  // Distribute space between the select and button
                        width: '100%',  // Full width for the container
                        alignItems: 'center',  // Vertically align items
                        gap: '4px',  // Space between the select and button
                        paddingLeft: '6px',
                        paddingRight: '6px',
                    }}>
                        <select className="testing-custom-chart-dropdown" style={{ flex: '1' }}>
                            {dropdownOptions.map((option) => (
                                <option key={option} value={option.toLowerCase()}>
                                    {option}
                                </option>
                            ))}
                        </select>
                        <Button
                            variant={!idocChartVisible ? "outline-primary" : "warning"}
                            size="sm"
                            onClick={handleViewClick}
                            style={{
                                flex: '1',  // Make select box expand to take available space
                                height: '26px',  // Set height to match button
                                fontSize: '12px',  // Smaller font for compact appearance
                            }}
                        >
                            Custom chart
                        </Button>
                    </div>

                </div>
            </div>
        </>
    );
}

export default IdocProcessingChart